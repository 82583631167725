import { AuthenticationModel, Right } from './../services/api-services';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, delay, finalize, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationApiService } from './api-services';
import StorageHelper from '../helpers/storage.helper';
import { StringHelper } from '../helpers/string.helper';

@Injectable({
  providedIn: 'root'
})
// Stores and handles the authentication state of the user and all the tokens.
// It can also handle different users per browser over multiple tabs (allowMultipleUsers).
export class AuthenticationService {
  private readonly authenticationKey = 'AxedrasGBI.authentication';
  private readonly currentUniqueNameKey = 'AxedrasGBI.currentUniqueName';
  private readonly isRefreshingTokenKey = 'AxedrasGBI.isRefreshingToken';
  private readonly authenticatedSubject$ = new ReplaySubject<AuthenticationModel | null>(1);
  public readonly authenticated$ = this.authenticatedSubject$.asObservable();
  private decodedAccessToken: any | null = null; // Used to store the decoded access token
  private trackedAccessToken: string | null = null; // Used to compare the current access token with the stored one
  private readonly allowMultipleUsers = true; // Flag to determine if multiple users are allowed per browser or not
  private uniqueName: string | null = null; // Used as suffix for local storage
  private static email: string | null = null; // Static property to store the e-mail of the user and allow external access (Logging)

  constructor(private readonly authenticationApiService: AuthenticationApiService) {
    this.uniqueName = StorageHelper.getItemAsObject<string>(localStorage, this.currentUniqueNameKey);

    const authentication = StorageHelper.getItemAsObject<AuthenticationModel>(localStorage, this.getUniqueStorageKey(this.authenticationKey));

    this.setAuthentication(authentication);
  }

  public setAuthentication(authentication: AuthenticationModel | null): void {
    if (authentication) {
      // Store the decoded access token in memory so it must not be decoded again
      this.decodedAccessToken = this.decodeJwt(authentication.access_token);
      // Hold the e-mail in a static property to be able to access it from everywhere (logger)
      AuthenticationService.email = this.decodedAccessToken.email;

      if (this.allowMultipleUsers) {
        // Store the unique name of the user to build a unqiue key for the local storage
        this.uniqueName = this.decodedAccessToken.unique_name;
        // Store the current users unique name so if the user opens a new tab, it will use this user
        StorageHelper.setItemFromObject<string | null>(localStorage, this.currentUniqueNameKey, this.uniqueName);
      }

      // Store access and refresh token
      StorageHelper.setItemFromObject<AuthenticationModel>(localStorage, this.getUniqueStorageKey(this.authenticationKey), authentication);
      // Track current access token, to determine changes
      this.trackedAccessToken = authentication.access_token;
    }
    else {
      this.decodedAccessToken = null;
      // Remove the items if data was null or undefined
      StorageHelper.clear(localStorage, this.getUniqueStorageKey(this.authenticationKey));

      if (this.allowMultipleUsers) {
        StorageHelper.clear(localStorage, this.getUniqueStorageKey(this.currentUniqueNameKey));
        this.uniqueName = null;
      }

      AuthenticationService.email = null;

      this.trackedAccessToken = null;
    }

    this.isRefreshingToken = false;
    this.authenticatedSubject$.next(authentication);
  }

  private getUniqueStorageKey(key: string): string {
    if (this.allowMultipleUsers && this.uniqueName) {
      return `${key}_${this.uniqueName}`;
    }

    return key;
  }

  public logout(): Observable<void> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken && this.isAuthenticated()) {
      return this.authenticationApiService.logout(refreshToken)
        .pipe(catchError(error => {
          // Ignore errors from the API so we don't interrupt the logout processes
          console.error(error);
          return of(null);
        }))
        .pipe(map(() => {
          return this.setAuthentication(null);
        }));
    }
    else {
      return of(this.setAuthentication(null));
    }
  }

  public getLoginUrl(redirectUrl?: string): string {
    return `/authentication/login${(redirectUrl ? `?redirectUrl=${redirectUrl}` : '')}`;
  }

  public getAccessToken(): string | null {
    if (this.trackedAccessToken !== (this.getAuthentication()?.access_token ?? null)) {
      // When the access token was changed (In an other browser tab), refresh the user info
      this.setAuthentication(this.getAuthentication());
    }

    if (this.allowMultipleUsers) {
      // Update the active unique name on each request
      StorageHelper.setItemFromObject<string | null>(localStorage, this.currentUniqueNameKey, this.uniqueName);
    }

    return this.getAuthentication()?.access_token ?? null;
  }

  public getRefreshToken(): string | null {
    return this.getAuthentication()?.refresh_token ?? null;
  }

  public tokenExpired(): boolean {
    const expiryDate = this.decodedAccessToken?.exp ?? 0;

    return Math.floor(new Date().getTime() / 1000) >= expiryDate;
  }

  public getUserId(): string | null {
    return this.decodedAccessToken?.unique_name;
  }

  public getFirstName(): string | null {
    return this.decodedAccessToken?.given_name;
  }

  public getLastName(): string | null {
    return this.decodedAccessToken?.family_name;
  }

  public getFullName(): string | null {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  public getRights(): Right[] {
    let rights = this.decodedAccessToken?.role;

    if (!rights?.length) {
      return [];
    }

    if (!Array.isArray(rights)) {
      rights = [rights];
    }

    return rights.map(right => Right[right as string] as Right);
  }

  public hasRight(right: Right): boolean {
    if (!this.getAuthentication()) {
      return false;
    }

    return this.getRights().includes(right);
  }

  public hasAnyRight(roles: Right[]): boolean {
    if (!this.getAuthentication()) {
      return false;
    }

    return this.getRights().some(role => roles.includes(role));
  }

  public isAuthenticated(): boolean {
    return !this.tokenExpired();
  }

  public authenticate(userName: string, password: string): Observable<AuthenticationModel | null> {
    return this.authenticationApiService.token(userName, password)
      .pipe(map((loggedInModel: AuthenticationModel | null) => {
        this.setAuthentication(loggedInModel);
        return loggedInModel;
      }));
  }

  public refreshToken(refreshToken?: string | null): Observable<AuthenticationModel | null> {
    if(this.isRefreshingToken) {
      // When using multiple tabs with the same access token, 
      // only one tab should refresh the token and consume the refresh token.
      // Therefor wait here until the token is refreshed and reuse the new tokens from the first tab.
      return this.checkForRefreshedTokenRecursive({ count: 1, max: 10, delayMilliseconds: 300 });
    }

    if (!refreshToken) {
      refreshToken = this.getRefreshToken();
    }

    if (refreshToken) {
      this.isRefreshingToken = true;

      return this.authenticationApiService.refresh(refreshToken, null)
        .pipe(
          map((loggedInModel: AuthenticationModel | null) => {
            this.setAuthentication(loggedInModel);

            return loggedInModel;
          }),
          catchError((error, caught) => {
            this.setAuthentication(null);

            return of(null);
          }),
          finalize(() => this.isRefreshingToken = false));
    }
    else {
      return of(null)
    }
  }

  public static getEmail(): string | null {
    return AuthenticationService.email;
  }

  private decodeJwt(token: string): any {
    return JSON.parse(StringHelper.decodeBase64(token.split('.')[1]));
  }

  private getAuthentication(): AuthenticationModel | null {
    return StorageHelper.getItemAsObject<AuthenticationModel>(localStorage, this.getUniqueStorageKey(this.authenticationKey));
  };
  
  private checkForRefreshedTokenRecursive(counter: { count: number, max: number, delayMilliseconds }) : Observable<AuthenticationModel | null> {
    if(!this.isRefreshingToken) {
      console.log(`Other tab has refreshed the token.`);
      return of(StorageHelper.getItemAsObject<AuthenticationModel>(localStorage, this.getUniqueStorageKey(this.authenticationKey)));
    }
    else if(counter.count < counter.max) {
      const totalDelayMilliseconds = counter.delayMilliseconds * counter.count; // Increase delay linearly
      counter.count = counter.count + 1;
      console.log(`Other tab has not yet refreshed the token. Waiting ${totalDelayMilliseconds}ms...`);
      return of(null)
        .pipe(delay(totalDelayMilliseconds))
        .pipe(switchMap(() => this.checkForRefreshedTokenRecursive(counter)));
    }

    console.log(`Other tab has NOT refreshed the token. Every tab will be signed out.`);

    return of(null);
  }

  private get isRefreshingToken(): boolean {
    return StorageHelper.getItemAsObject<boolean>(localStorage, this.getUniqueStorageKey(this.isRefreshingTokenKey)) ?? false;
  }

  private set isRefreshingToken(value: boolean) {
    const key = this.getUniqueStorageKey(this.isRefreshingTokenKey);

    if (!value) {
      StorageHelper.clear(localStorage, key);
      return;
    }

    StorageHelper.setItemFromObject<boolean>(localStorage, key, value);
  }
}
