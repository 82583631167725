export default class StorageHelper {
  static setItemFromObject<T>(storage: Storage, key: string, item: T): void {
    storage.setItem(key, JSON.stringify(item));
  }

  static getItemAsObject<T>(storage: Storage, key: string): T | null {
    try {
      return JSON.parse(storage.getItem(key)!) as T;
    }
    catch {
      this.clear(storage, key);
    }

    return null;
  }

  public static clear(storage: Storage, key: string): void {
    if (!key) {
      return;
    }

    storage.removeItem(key);
  }
}
